<template lang="pug">
	b-card-body.table-search.justify-content-xs-around.px-0(v-if='!hideSearch || !hidePerPage')
		.row.dataTables_wrapper
			.col.dataTables_length.mb-2.mb-sm-0(v-if='!hidePerPage')
				b-select.d-inline-block.w-auto(size='md' v-model='perPageLocal' :options='optionsPages' @input='page()') entries
			.col(v-if='!hideSearch')
				b-input.d-inline-block.w-auto.float-sm-right(size='md' placeholder='Поиск...' @input='search($event)')
</template>

<script>
export default {
    name: 'DTableElSearch',
    props: {
        hideSearch: {
            type: Boolean,
            default: false,
        },
        hidePerPage: {
            type: Boolean,
            default: false,
        },
        optionsPages: {
            type: Array,
            default: () => [10, 25, 50, 100],
        },
        perPage: {
            type: Number,
            default: 25,
        },
    },
    data() {
        return {};
    },
    created() {
        this.perPageLocal = this.perPage;
    },
    computed: {},
    methods: {
        search(value) {
            this.$emit('search', value);
        },
        page() {
            this.$emit('changeperpage', this.perPageLocal);
        },
    },
};
</script>
