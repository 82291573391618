<template lang="pug">
	span.d-flex.cursor-pointer.pw-btn-icon(:class="{'used': used, 'disabled': disabled}" @click="$emit('click', $event)")
		i.fontello(:class="icon")
</template>
<script>
export default {
    name: 'PwBtnIcon',
    props: {
        icon: {
            default: '',
            type: String,
        },
        used: {
            default: false,
            type: Boolean,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.pw-btn-icon {
    padding: 5.2px 15px;
    border: 1px solid var(--grayscale-border);
    border-radius: var(--border-radius-rounded);
    transition: var(--animation-time-short);

    &:has(.fontello-icon-download-file) {
        padding: 0;
        height: 30px;
        width: 49.6px;
        align-items: center;
        justify-content: center;
    }

    i {
        font-size: 18px;
        line-height: 18px;
        color: var(--brand-primary);
        transition: var(--animation-time-short);
    }

    &:hover,
    &:active {
        border: 1px solid var(--brand-primary);

        i {
            color: var(--primary-dark-text-color);
        }
    }

    &.used {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: -4px;
            right: -5px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: var(--brand-orange);
            border: 3px solid var(--foreground-color);
        }
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .fontello-icon-download-file {
        font-size: 25px;
        line-height: 25px;
    }
}
</style>
