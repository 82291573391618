import { bus } from '@/main.js';

export default {
    props: {
        showPaginationBottom: {
            type: Boolean,
            default: false,
        },
        hideSearch: false,
        hidePerPage: false,
        hidePagination: false,
        hideInfo: false,
        bordered: false,
        perPage: {
            type: Number,
            default: 25,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            sortBy: 'id',
            sortDesc: true,
            fields: [],
            currentPage: 1,
            fixed: false,
            striped: false,
            hover: false,
            showempty: true,
            emptyText: '',
            sorting: false,
            rowclick: () => {},
            trClass: null,
            isbusy: true,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.perPage);
        },
        isLoaderShown() {
            return this.isbusy && this.currentPage <= this.totalPages && this.totalPages != 0;
        },
    },
    methods: {
        filter(value) {
            const val = value.toLowerCase();
            const filtered = this.originalUsersData.filter(d => {
                return (
                    Object.keys(d)
                        .filter(k => this.searchKeys.includes(k))
                        .map(k => String(d[k]))
                        .join('|')
                        .toLowerCase()
                        .indexOf(val) !== -1 || !val
                );
            });
            this.usersData = filtered;
        },

        pageLengthChanged(v) {
            this.perPage = v;
            this.items = [];
            this.sorting(this);
        },

        pageChanged(v) {
            this.currentPage = v;
            this.$emit('onChangeCurrentPage', this.currentPage);
            this.sorting(this);
        },
        searching() {
            return _.debounce(() => {
                this.sorting(this);
            }, 400);
        },
        parseFilters() {
            return _.each(_.omitBy(this.filters, _.isNil), (f, i, o) => {
                return (o[i] = f.id || (f.value != '' ? f.value : null));
            });
        },
        resetPagination() {
            this.currentPage = 1;
        },
    },
    watch: {
        filters: {
            handler(nV) {
                this.resetPagination();
                this.searching(nV.query);
            },
            deep: true,
        },
        perPage: {
            handler(nV) {
                this.resetPagination();
                this.pageLengthChanged(nV);
            },
        },
    },

    mounted() {
        bus.$on(`table${this.$options.name}Refresh`, this.resetPagination);
        this.emptyText = this.$t('errors.emptySearch');
    },
    destroyed() {
        bus.$off(`table${this.$options.name}Refresh`, this.resetPagination);
    },
};
